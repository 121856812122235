import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../axiosInstance'; import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import toast from 'react-hot-toast';
import Gallery from '../../components/Gallery';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Select from 'react-select';
import { triggerChooseimg } from '../../components/ChooseImg';
import jsPDF from 'jspdf';

import html2canvas from 'html2canvas';


const OrderView = () => {



  const [Order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [OrderPlace, setOrderPlace] = useState('');
  const [MYuser, setMYuser] = useState('');

  const { userId, orderId } = useParams();

  const navigate = useNavigate();

  const saveAsPDF = async () => {
    const printContent = document.getElementById('print');

    // Set the scale factor for better quality
    const scale = 5;

    // Set the quality of the JPEG image
    const imageQuality = 1; // 1 is maximum quality

    // Convert print content to image with higher scale
    html2canvas(printContent, {
      scale: scale,
      useCORS: true, // Enable CORS to support loading images from different origins
      logging: true // Enable logging for debugging
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg', imageQuality); // Use JPEG format with specified quality

      // Calculate dimensions of PDF page
      const pdfWidth = 297; // A4 width in mm
      const pdfHeight = 210; // A4 height in mm
      const ratio = canvas.width / canvas.height;

      let pdfHeightAdjusted = pdfHeight;
      let pdfWidthAdjusted = pdfWidth;

      if (ratio < pdfWidth / pdfHeight) {
        pdfWidthAdjusted = pdfHeight * ratio;
      } else {
        pdfHeightAdjusted = pdfWidth / ratio;
      }

      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: 'landscape', // Set the orientation of the PDF to landscape
        unit: 'mm', // Use millimeters as the unit for measurements
        format: [pdfWidth, pdfHeight] // Set the format of the PDF (A4 size)
      });

      // Add image to PDF document
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidthAdjusted, pdfHeightAdjusted);

      // Save the PDF
      pdf.save("invoice.pdf");
    });
  }



  const getUserOrders = async () => {

    try {
      // const id = localStorage.getItem('userId');
      const { data } = await axiosInstance.get(`/user-orders-view/${userId}/${orderId}`);

      if (data?.success) {
        console.log('order', data)
        setOrder(data?.userOrder);

      }
      setIsLoading(false); // Set loading state to false after fetching data

    } catch (error) {
      console.log(error);
      setIsLoading(false); // Set loading state to false in case of an error
      toast.error("order Not found");
      navigate('/all-order');
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }




  useEffect(() => {

    getUserOrders();

  }, []);



  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                OrderID #{Order._id}
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">OrderID #{Order._id}</h1>
            </div>
            <div>
              {" "}
              <Link
                to="/all-order"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>

        <div id="main-content" className="bg-transparent ">

          {isLoading ? (
            // Display loading skeletons while data is being fetched
            Array.from({ length: 2 }).map((_, index) => (
              <div className="col-md-12" key={index}>

                <div className="skeleton mb-3" style={{ height: 154, borderRadius: 2 }} />

              </div>
            ))
          ) :
            (<>


              <div className="w-100" id="print">

                {/* Main content */}
                <div className="row" >
                  <div className="col-lg-8">
                    {/* Details */}
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="me-3"> {formatDate(Order.createdAt)}</span>
                            <span className="me-3">#{Order._id}</span>
                            <span className="me-3">{Order.mode}</span>
                            <span className="badge rounded-pill bg-info">SHIPPING</span>
                          </div>
                          <div className="d-flex">
                            <button onClick={saveAsPDF} className="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text">
                              <i className="bi bi-download" />{" "}
                              <span className="text">Invoice</span>
                            </button>
                            <div className="dropdown">
                              <button
                                className="btn btn-link p-0 text-muted"
                                type="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bi bi-three-dots-vertical" />
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-pencil" /> Edit
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-printer" /> Print
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <table className="table table-borderless">
                          <tbody>
                            {Order.items.map((Pro) => (
                              <tr>
                                <td>
                                  <div className="d-flex mb-2">
                                    <div className="flex-shrink-0">
                                      <img
                                        src={Pro.image}
                                        alt=""
                                        width={35}
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="flex-lg-grow-1 ms-3">
                                      <h6 className="small mb-0">
                                        <Link href={`/product/${Pro.id}`} className="text-reset">
                                          {Pro.title} - GST {Pro.gst}%
                                        </Link>
                                      </h6>

                                    </div>
                                  </div>
                                </td>
                                <td> {Pro.quantity} </td>
                                <td className="text-end">₹{Pro.price}</td>
                              </tr>
                            ))}



                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2}>Subtotal </td>
                              <td className="text-end">₹{Order.items.reduce((total, item) => total + item.quantity * item.price, 0) - Math.floor(
                                Order.items.reduce((acc, item) => {
                                  const itemPrice = item.quantity * item.price;
                                  const itemGST = (itemPrice * item.gst) / 100;
                                  return acc + itemGST;
                                }, 0)
                              )}</td>
                            </tr>


                            {Order.primary === 'true' ? (<>
                              <tr>
                                <td colSpan={2}>CGST @{Order.items.reduce((total, item) => total + item.quantity * item.gst, 0) / 2}%</td>
                                <td className="text-end">₹
                                  {Math.floor(
                                    Order.items.reduce((acc, item) => {
                                      const itemPrice = item.quantity * item.price;
                                      const itemGST = (itemPrice * item.gst) / 100;
                                      return acc + itemGST;
                                    }, 0)
                                  ) / 2} </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>SGST @{Order.items.reduce((total, item) => total + item.quantity * item.gst, 0) / 2}%</td>
                                <td className="text-end">₹
                                  {Math.floor(
                                    Order.items.reduce((acc, item) => {
                                      const itemPrice = item.quantity * item.price;
                                      const itemGST = (itemPrice * item.gst) / 100;
                                      return acc + itemGST;
                                    }, 0)
                                  ) / 2} </td>
                              </tr>

                            </>) : (<>


                              <tr>
                                <td colSpan={2}>IGST @{Order.items.reduce((total, item) => total + item.quantity * item.gst, 0)}%</td>
                                <td className="text-end">₹
                                  {Math.floor(
                                    Order.items.reduce((acc, item) => {
                                      const itemPrice = item.quantity * item.price;
                                      const itemGST = (itemPrice * item.gst) / 100;
                                      return acc + itemGST;
                                    }, 0)
                                  )} </td>
                              </tr>
                            </>)}






                            <tr>
                              <td colSpan={2}>Shipping</td>
                              <td className="text-end">₹{Order.shipping}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>Discount </td>

                              <td className="text-danger text-end"> {Order.items.reduce((total, item) => total + item.quantity * item.price, 0) - Math.abs(Order.discount) === 0 ? '₹0' : (
                                <>- ₹{Math.abs(Order.discount)}
                                </>
                              )}  </td>
                            </tr>
                            <tr className="fw-bold">
                              <td colSpan={2}>TOTAL</td>
                              <td className="text-end">₹{Order.totalAmount}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    {/* Payment */}
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <h3 className="h6">Payment Method</h3>
                            <p>
                              Method : {Order.mode} <br />
                              Total :  ₹{Order.totalAmount} {'  '}
                              <span className={`badge ${Order.payment === 1 ? 'bg-success' : 'bg-warning'} rounded-pill`}>{Order.payment === 1 ? 'PAID' : 'UNPAID'}</span>
                            </p>
                          </div>
                          {/* <div className="col-lg-6">
                                    <h3 className="h6">Billing address</h3>
                                    <address>
                                      <strong>John Doe</strong>
                                      <br />
                                      1355 Market St, Suite 900
                                      <br />
                                      San Francisco, CA 94103
                                      <br />
                                      <b title="Phone">Phone:</b> 9876543210 <br />
                                      <b title="Email">Email:</b> info@cayroshop.com <br />

                                    </address>
                                  </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    {/* Customer Notes */}
                    <div className="card mb-4">
                      <div className="card-body">
                        <h3 className="h6">Company Information</h3>
                        <hr />

                        <address>
                          <strong>cayroshop.com</strong>
                          <br />
                          New Delhi 1110045
                          <br />

                          <br />
                          <b title="Phone">Phone:</b> 9876543210 <br />
                          <b title="Phone">Email:</b> info@cayroshop.com <br />
                          <b title="Phone">Web:</b>www.cayroshop.com <br />
                        </address>
                      </div>
                    </div>
                    <div className="card mb-4">
                      {/* Shipping information */}
                      <div className="card-body">
                        <h3 className="h6">Shipping Information</h3>
                        <hr />

                        <h3 className="h6">Address</h3>
                        <address>
                          <strong>John Doe</strong>
                          <br />
                          {Order.details[0].address}
                          <br />
                          <b title="Phone">Phone:</b>  {Order.details[0].phone}  <br />
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </>)}





        </div>


      </main>
    </>
  )
}

export default OrderView