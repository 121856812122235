import React, { useState, useEffect, useContext, Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import toast from "react-hot-toast";

const Dashboard = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const checkUserToken = async () => {
            console.log('Effect is running');
            // Check if this is printed multiple times
            const usertoken = localStorage.getItem('adminToken');
            if (!usertoken) {
                console.log('Token !found in local storage');

                navigate('/admin');

                toast.success("Please login first");
            }
        }
        checkUserToken();
    }, [navigate]);


    return (
        <>
            <Sidebar />
            <main class="content">
                <Navbar />


                <div className="py-4">
                    <div className="dropdown">
                        <Link
                            to="/addBlog#blogs"
                            className="btn btn-gray-800 d-inline-flex align-items-center me-2 dropdown-toggle"
                        >
                            <svg
                                className="icon icon-xs me-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                            </svg>
                            Add Blog
                        </Link>
                    </div>
                </div>


                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="card bg-primary text-white mb-4">
                            <div className="card-body">All Blogs</div>
                            <div className="card-footer d-flex align-items-center justify-content-between">
                                <Link
                                    className="small text-white stretched-link"
                                    to="/allblogs"
                                >
                                    View Details
                                </Link>
                                <div className="small text-white">
                                    <svg
                                        className="svg-inline--fa fa-angle-right"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="angle-right"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                                        />
                                    </svg>
                                    {/* <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card bg-warning text-white mb-4">
                            <div className="card-body">Add Blogs</div>
                            <div className="card-footer d-flex align-items-center justify-content-between">
                                <Link
                                    className="small text-white stretched-link"
                                    to="/addBlog#blogs"
                                >
                                    View Details
                                </Link>
                                <div className="small text-white">
                                    <svg
                                        className="svg-inline--fa fa-angle-right"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="angle-right"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                                        />
                                    </svg>
                                    {/* <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card bg-success text-white mb-4">
                            <div className="card-body">Gallery</div>
                            <div className="card-footer d-flex align-items-center justify-content-between">
                                <Link className="small text-white stretched-link" to="/add-gallery">
                                    View Details
                                </Link>
                                <div className="small text-white">
                                    <svg
                                        className="svg-inline--fa fa-angle-right"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="angle-right"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 256 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                                        />
                                    </svg>
                                    {/* <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>
        </>
    )
}

export default Dashboard